import React from 'react';
import type { ReactNode } from 'react';

import classNames from 'classnames';

import Tooltip from 'rc-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { Button, FlexBox } from '@eltoro-ui/components';

import CustomIcons from 'assets/icons';

import { OrderlineStatusEnum } from 'enums';

import './OrderlineDetailsContainer.scss';

interface OrderlineDetailsContainerProps {
  title: string;
  rejectionNote?: string | null;
  children?: ReactNode;
  handleOpenEditModal?: (open: boolean) => void;
  status: OrderlineStatusEnum;
}
export default function OrderlineDetailsContainer({
  title,
  rejectionNote,
  children,
  handleOpenEditModal,
  status,
}: OrderlineDetailsContainerProps) {
  const isAudineceEditable = [
    OrderlineStatusEnum.ACTIVE,
    OrderlineStatusEnum.APPROVED,
    OrderlineStatusEnum.REJECTED,
  ].includes(status);

  const isCreativeEditable = [
    OrderlineStatusEnum.ACTIVE,
    OrderlineStatusEnum.PAUSED,
    OrderlineStatusEnum.APPROVED,
    OrderlineStatusEnum.REJECTED,
  ].includes(status);

  const rejectionOverlay = (
    <FlexBox flexDirection="column">
      <span>
        Your ad was rejected. Check the rejection note for details and make the needed updates.
      </span>
      <span>Rejection Note: "{rejectionNote}"</span>
    </FlexBox>
  );

  return (
    <FlexBox flexDirection="column" gap="32px" UNSAFE_className="orderline-details-container">
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexBox alignItems="center" gap="0.75rem">
          <span className="orderline-details-container-title">{title}</span>
          {status === OrderlineStatusEnum.REJECTED && rejectionNote && (
            <Tooltip
              placement="left"
              trigger="hover"
              overlayClassName={classNames('audience-tooltip', 'save-audience-info')}
              showArrow
              align={{
                offset: [-8, 0],
                targetOffset: [-6, 0],
              }}
              overlay={rejectionOverlay}
              getTooltipContainer={() => document.body}
            >
              <span style={{ cursor: 'pointer', display: 'flex' }}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ fontSize: 18, color: '#FFAB03' }}
                />
              </span>
            </Tooltip>
          )}
        </FlexBox>
        {((title === 'Creatives' && isCreativeEditable) ||
          (title === 'Attached Audiences' && isAudineceEditable)) && (
          <Button
            kind="link"
            size="m"
            weight="bold"
            iconLeft={<CustomIcons name="edit" fontSize={16} />}
            onClick={() => handleOpenEditModal && handleOpenEditModal(true)}
          >
            Edit
          </Button>
        )}
      </FlexBox>
      <FlexBox flexDirection="column" UNSAFE_className="orderline-details-container-content">
        {children}
      </FlexBox>
    </FlexBox>
  );
}
