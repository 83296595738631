import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import Tooltip from 'rc-tooltip';

import classNames from 'classnames';
import toast from 'react-hot-toast';

import { Button, MaxHeightContainer, Text } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/pro-solid-svg-icons';

import { Layout, Loading, PageHeader, ProgressStepper, SummaryCart } from 'Components';
import { CampaignCreationFooter, CampaignCreationWrapper } from 'Pages/CampaignCreation/components';
import CampaignErrorInfo from 'Pages/CampaignCreation/components/CampaignErrorInfo';

import HasSelectedGoalInfoBar from 'Pages/CampaignCreation/components/CampaignSetup/HasSelectedGoalInfoBar';
import AudiencesWithGoals from 'Pages/CampaignCreation/components/CampaignSetup/AudiencesWithGoals';

import { reduceProspects } from 'Utils/helpers';

import { setCampaignGoal, setCampaignId, setSpinner } from 'Redux/actions';

import {
  getCampaignAudience,
  getCampaignById,
  getCampaignTargets,
  saveCampaignTarget,
} from 'Requests/Request_Methods/campaignMethods';

import { campaignCreationStepsAccessStatuses } from 'Utils/constants';

import { CampaignStatusEnum, CampaignStep, GoalSectionTitles } from 'enums';
import type { CampaignType, TRootState, TTarget, TTargets, TTargetAllGoalAudiences } from 'types';

import './CampaignSetup.scss';

export type CampaignSetupParams = { campaignId: string };

export const CampaignSetup = (props: RouteComponentProps<CampaignSetupParams>) => {
  const { campaignId: campaignIdParams } = props.match.params;

  const dispatch = useDispatch();
  const history = useHistory();

  const initialTargetData = {
    campaign_id: campaignIdParams,
    targets: [] as TTargets,
    total: 0,
    total_pages: 0,
    current_page: 1,
  };

  const campaignId = useSelector((state: TRootState) => state.campaignIdReducer);
  const isLaunchCampaign = useSelector((state: TRootState) => state.isCampaignLaunchReducer);
  const [campaignName, setCampaignName] = useState<string>('');
  const [currentCampaign, setCurrentCampaign] = useState<CampaignType | null>(null);
  const [selectedAudiences, setSelectedAudiences] = useState<TTargets>([]);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const [audiencesWithGoal, setAudiencesWithGoal] = useState<TTargetAllGoalAudiences>({
    campaign_goal: null,
    campaign_id: currentCampaign?.id,
    current_page: 1,
    goals: {
      [GoalSectionTitles.CONQUEST_LEADS]: initialTargetData,
      [GoalSectionTitles.RETENTION]: initialTargetData,
      [GoalSectionTitles.PROMOTE_LISTING]: initialTargetData,
      [GoalSectionTitles.BRANDING_AWARENESS]: initialTargetData,
    },
    total: 1,
    total_pages: 0,
    using_goals: [],
  });

  const sum = useMemo(() => {
    return selectedAudiences.reduce((prevValue, item) => prevValue + item.prospects_counts, 0);
  }, [selectedAudiences]);

  const [audiencePaginate, setAudiencePaginate] = useState<{
    total: number;
    current_page: number;
    total_page: number;
  }>({
    total: 1,
    current_page: 1,
    total_page: 0,
  });

  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const campaignAudienceAPI = (id: string) => {
    setIsloading(true);
    getCampaignAudience(id, audiencePaginate?.current_page, 10, isShowContact).then(res => {
      if (res.data) {
        setAudiencePaginate({
          current_page: res.data.current_page,
          total: res.data.total,
          total_page: res.data.total_pages,
        });
      }
      setIsloading(false);
    });
  };

  const campaignAudienceTarget = (id: string) => {
    setIsloading(true);
    setAudiencesWithGoal({
      campaign_goal: null,
      campaign_id: undefined,
      current_page: 1,
      goals: {
        [GoalSectionTitles.CONQUEST_LEADS]: initialTargetData,
        [GoalSectionTitles.RETENTION]: initialTargetData,
        [GoalSectionTitles.PROMOTE_LISTING]: initialTargetData,
        [GoalSectionTitles.BRANDING_AWARENESS]: initialTargetData,
      },
      total: 1,
      total_pages: 0,
      using_goals: [],
    });
    getCampaignTargets(id, audiencePaginate?.current_page, 10)
      .then(res => {
        if (res.data) {
          setAudiencesWithGoal(res.data);
          if (res.data.campaign_goal) {
            dispatch(setCampaignGoal(res.data.campaign_goal));
          }
        }
        getCampaignDetails(id);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const callIsSelectedForGoals = (currentCampaign: CampaignType | null) => {
    if (currentCampaign?.audience) {
      const selectableAudiences = [...currentCampaign?.audience];
      setSelectedAudiences([...selectableAudiences] as TTargets);
      setCurrentCampaign(campaign =>
        campaign
          ? {
              ...campaign,
              prospects_counts: reduceProspects([...selectableAudiences]),
              audiences: selectableAudiences,
              audience_count: selectableAudiences.length,
            }
          : null
      );
      setFirstLoad(false);
    }
  };

  useEffect(() => {
    campaignAudienceTarget(campaignIdParams);
  }, [campaignIdParams]);

  const handleNextClick = () => {
    const targets = selectedAudiences.map(({ type, id, campaign_id, prospects_counts }) => ({
      type: type || 'audience',
      id,
      campaign_id: campaign_id || campaignIdParams,
      prospects_counts,
    }));

    dispatch(setSpinner(true));

    saveCampaignTarget({ targets }, campaignId.campaignId?.id)
      .then(res => {
        if (res.data) {
          const item = {
            audiences: res.data.audience,
            prospects: res.data.prospects_count,
          };
          history.push({
            pathname: `/create-campaign/${campaignId.campaignId?.id}/creatives`,
            state: item,
          });
        }
      })
      .catch(() => {
        toast.error('There are some issues with the audience(s) you’ve selected.');
        campaignAudienceTarget(campaignIdParams);
      })
      .finally(() => {
        dispatch(setSpinner(false));
      });
  };

  useEffect(() => {
    if (campaignIdParams) {
      getCampaignDetails(campaignIdParams);
    }
  }, [campaignIdParams]);

  const getCampaignDetails = (id: any) => {
    const getCampaign = async () => {
      try {
        setCurrentCampaign(null);
        setSelectedAudiences([]);
        setFirstLoad(true);
        setIsloading(true);
        const { data } = await getCampaignById(id);
        if (data) {
          dispatch(setCampaignGoal(data.goal));
          if (!campaignCreationStepsAccessStatuses.includes(data.status))
            history.replace('/orderlines');

          if (data.step === CampaignStep.DONE)
            if (
              data.status === CampaignStatusEnum.ERROR ||
              data.status === CampaignStatusEnum.CREATING
            )
              history.replace('/orderlines');

          setCampaignName(data?.name || '');
          dispatch(setCampaignId({ id: id || campaignIdParams }));
          setCurrentCampaign(data);

          if (data?.id === +campaignIdParams && data?.audience) {
            callIsSelectedForGoals(data);
          }
        }
        setIsloading(false);
      } catch (e) {
        toast.error(e);
      } finally {
        setIsloading(false);
      }
    };
    getCampaign();
  };

  const [isLoading, setIsloading] = useState(true);
  const [isShowContact, setIsShowContact] = useState(false);

  useEffect(() => {
    if (!uploadModalOpen && campaignName) {
      getCampaignDetails(campaignId?.campaignId?.id || campaignIdParams);
      if (audiencePaginate.current_page === 1) campaignAudienceAPI(campaignIdParams);
      else
        setAudiencePaginate({
          total: 1,
          current_page: 1,
          total_page: 0,
        });
    }
  }, [uploadModalOpen]);

  const onSelect = (audienceList: TTarget[], goal: GoalSectionTitles) => {
    setSelectedAudiences(prev => {
      // Filter out audiences with the same goal as the current table
      const filteredPrev = prev.filter(audience => audience.goal !== goal);

      // Combine filtered previous audiences with the new audience list
      const mergedList = [...filteredPrev, ...audienceList].filter(
        (audience, index, self) => self.findIndex(item => item.id === audience.id) === index
      );

      setCurrentCampaign(campaign =>
        campaign
          ? {
              ...campaign,
              prospects_counts: reduceProspects(mergedList),
              audiences: mergedList,
              audience_count: mergedList.length,
            }
          : null
      );

      return mergedList;
    });
  };

  const renderAudiences = () => {
    if (isLoading && !audiencesWithGoal) {
      return <Loading />;
    }

    return (
      <AudiencesWithGoals
        allAudienceData={audiencesWithGoal}
        selectedAudiences={selectedAudiences}
        onSelect={onSelect}
      />
    );
  };

  return (
    <div className="CampaignSetup">
      <MaxHeightContainer
        fullHeight
        header={
          <PageHeader
            UNSAFE_CLASSNAME_GRID="CampaignSetup__titleSpace"
            UNSAFE_CLASSNAME="CampaignSetup__header"
            UNSAFE_CLASSNAME_TITLE="CampaignCheckout__title "
            actionsStyle="CampaignSetup__progress"
            title={campaignName ?? (isLaunchCampaign?.campaignId?.name || '')}
            actions={[
              <ProgressStepper
                key="progress-stepper"
                tagNames={['Audience', 'Creative', 'Checkout']}
                activeStep={1}
              />,
            ]}
          />
        }
        footer={
          <CampaignCreationFooter>
            <div className="CampaignSetup__bottom_details">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="prospects-selected">
                  <FontAwesomeIcon style={{ color: '#FFAB03' }} icon={faWalking} />{' '}
                  <Text UNSAFE_className="" on="primary-lighter" size="l">
                    <b>{sum}</b> <span>Prospect Selected</span>
                  </Text>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Text
                    on="primary-lighter"
                    size="xs"
                    UNSAFE_className="CampaignSetup__targeted_audience"
                  >
                    from {selectedAudiences.length} Targeted Audience
                  </Text>
                </div>
              </div>
            </div>
            <div className="CampaignSetup__next-button">
              <Tooltip
                placement="top"
                trigger="hover"
                overlayClassName={classNames('audience-tooltip', 'use-audience-prospects')}
                showArrow
                overlay="Please select additional audiences to reach a total of more than 20 prospects."
                {...(sum < 20 ? {} : { visible: false })}
                getTooltipContainer={() => document.body}
              >
                <Button
                  size="l"
                  UNSAFE_className="CampaignSetup__campaign_use_total_prospects"
                  onClick={handleNextClick}
                  disabled={!campaignId.campaignId || !selectedAudiences.length || sum < 20}
                >
                  <span>
                    Use <b>{sum} Prospects</b>
                  </span>
                </Button>
              </Tooltip>
            </div>
          </CampaignCreationFooter>
        }
      >
        <Layout>
          {currentCampaign?.status === CampaignStatusEnum.ERROR &&
            currentCampaign?.step === CampaignStep.AUDIENCE && (
              <CampaignErrorInfo
                title="There were some issues with the attached audience(s), which have been automatically removed."
                subTitle="Please consider selecting different audiences."
              />
            )}
          <CampaignCreationWrapper>
            <div>
              <div className="CampaignSetup__audiences">
                <Text size="xxl" on="white" weight="bold" UNSAFE_className="heading">
                  Audience
                </Text>
                <Text
                  on="white"
                  size="s"
                  UNSAFE_className="CampaignSetup__subheading CampaignSetup__bordered"
                >
                  Create a new Target Audience or pick one from the list below.
                </Text>
                {currentCampaign?.goal && <HasSelectedGoalInfoBar />}
                <div className="CampaignSetup__audience-list">{renderAudiences()}</div>
              </div>
            </div>
            {currentCampaign && <SummaryCart campaignCostAccData={{ campaign: currentCampaign }} />}
          </CampaignCreationWrapper>
        </Layout>
      </MaxHeightContainer>
    </div>
  );
};
