import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import toast from 'react-hot-toast';

import type { ButtonProps } from 'primereact/button';
import { Button as PrimeButton } from 'primereact/button';
import { Menu } from 'primereact/menu';
import type { MenuItem } from 'primereact/menuitem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faPlus, faVideo } from '@fortawesome/pro-regular-svg-icons';
import { faWandMagic } from '@fortawesome/pro-light-svg-icons';

import { useCanvaIntegrationContext } from 'contexts/CanvaIntegrationContext';

import CustomIcons from 'assets/icons';
import { PageHeader, ReactTableSearchBar } from 'Components';
import { Button, Modal } from '@eltoro-ui/components';
import { CreativeGen } from '@eltoro-ui/creative_generator';

import { usePermissionContext } from 'contexts/PermissionContext';
import { useMagicCreativeContext } from 'contexts/MagicCreativeContext';

import type { CreativeCampaignModalProps } from 'Pages/CampaignCreation/components/CampaignCreatives/_components';
import { CreativeCampaignModal } from 'Pages/CampaignCreation/components/CampaignCreatives/_components';
import CreativesList from 'Pages/CreativesLibrary/components/CreativesList';
import CanvaDesignsModal from 'Pages/CreativesLibrary/components/CanvaDesignsModal';
import MagicCreativeGenerator from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator';

import { getAllCreatives, uploadCreatives } from 'Requests/Request_Methods/creativesLibraryMethods';

import { getCanvaAuthorization } from 'canva-integration/services';

import { CampaignGoals } from 'Utils/constants';

import { steps } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/constants';

import { CreativeStatusEnum, CreativeTypeEnum } from 'enums';
import type { TCreative, TResPagination, TRootState } from 'types';

import './CreativesLibrary.scss';

type CreativeType = CreativeTypeEnum | 'csv' | null;

export type CreativeStatusItem = {
  name: CreativeStatusEnum;
};

export type CreativeTypeItem = {
  name: CreativeTypeEnum;
};

export type CreativeFilter = {
  creative_type: CreativeTypeItem[];
  status_type: CreativeStatusItem[];
};

const CreativesLibrary = () => {
  const addCreativesRef = useRef<Menu>(null);
  const menuRootWrapperRef = useRef<HTMLDivElement>(null);

  const { userData } = useSelector((state: TRootState) => state.userReducer);

  const [searchValue, setSearchValue] = useState('');
  const { userIsDeactivatedWithActiveOrderlines, userIsActive } = usePermissionContext();
  const { onSelectGoal, onStepChange, clearAllStates } = useMagicCreativeContext();
  const { isAuthorized, setIsAuthorized } = useCanvaIntegrationContext();

  const [canvaDesignsModalOpen, setCanvaDesignsModalOpen] = useState<boolean>(false);

  const [selectedCreativeUploadType, setSelectedCreativeUploadType] = useState<CreativeType>(null);
  const [creativeGenerator, setCreativeGenerator] = useState<boolean>(false);
  const [magicBannerGeneratorOpen, setMagicBannerGeneratorOpen] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  const [creatives, setCreatives] = useState<TCreative[]>([]);
  const [creative_filter, setCreativeFilter] = useState<CreativeFilter>({
    creative_type: [],
    status_type: [],
  });
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<TResPagination>({
    total_count: 0,
    total_pages: 0,
  });
  const [creativesLoading, setCreativesLoading] = useState<boolean>(false);

  const handleCanvaDesignsModalOpen = (open: boolean) => () => setCanvaDesignsModalOpen(open);
  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };
  const getCreatives = async (page: number, creative_filter: CreativeFilter) => {
    try {
      setCreativesLoading(true);
      const body = {
        creative_type: creative_filter.creative_type.map(type => type.name),
        status: creative_filter.status_type.map(status => status.name),
      };

      const res = await getAllCreatives(page, 10, body, searchValue);
      if (res.data) {
        const { creatives, total_creatives, total_pages } = res.data;
        setCreatives(creatives);
        setPagination({ total_count: total_creatives, total_pages });
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setCreativesLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setCreatives([]);
      setPagination({
        total_count: 0,
        total_pages: 0,
      });
    };
  }, []);

  useEffect(() => {
    if (userIsActive) {
      getCreatives(page, creative_filter);
    }
  }, [page, creative_filter, searchValue]);

  const onSelectCreativeType =
    (type: CreativeType): MenuItem['command'] =>
    () =>
      setSelectedCreativeUploadType(type);

  const onConnectClick = async () => {
    try {
      const result = await getCanvaAuthorization(userData.email);
      setIsAuthorized(result);
      if (result) setCanvaDesignsModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const addCreativesMenuItems: MenuItem[] = [
    {
      label: 'Banner',
      icon: <FontAwesomeIcon icon={faImage} />,
      command: onSelectCreativeType(CreativeTypeEnum.BANNER),
    },
    {
      label: 'Video',
      icon: <FontAwesomeIcon icon={faVideo} />,
      command: onSelectCreativeType(CreativeTypeEnum.VIDEO),
    },
    {
      label: 'Generate with AI',
      icon: <FontAwesomeIcon icon={faWandMagic} />,
      command: () => setMagicBannerGeneratorOpen(true),
    },
    {
      label: isAuthorized ? 'Upload form Canva' : 'Connect to Canva',
      icon: <CustomIcons name="canva_icon" fontSize={20} />,
      command: !isAuthorized ? onConnectClick : handleCanvaDesignsModalOpen(true),
    },
  ];

  const toggleCreativeGenerator = (open: boolean) => () => setCreativeGenerator(open);

  const toggleCreativeTypeSelect: ButtonProps['onClick'] = event => {
    if (addCreativesRef && addCreativesRef.current) {
      addCreativesRef.current.toggle(event);
    }
  };

  const onSubmit: CreativeCampaignModalProps['onSubmit'] = async files => {
    try {
      setUploadLoading(true);
      const formData = new FormData();
      files.acceptedFiles.forEach(file => formData.append('files', file));

      await uploadCreatives(formData);

      toast.success('Uploaded Successfully!');
      if (page === 1) getCreatives(1, creative_filter);
      else setPage(1);
    } catch (e) {
      if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
      else toast.error('Something went wrong!');
    } finally {
      setUploadLoading(false);
    }
  };

  const onCompleteCreativeGen = async (base64s: any[]) => {
    try {
      setUploadLoading(true);
      const formData = new FormData();
      const generated_banners = base64s.map(base64 => base64.handleDataURL);
      formData.append('body', JSON.stringify({ generated_banners }));

      await uploadCreatives(formData);

      toast.success('Uploaded Successfully!');
      if (page === 1) getCreatives(1, creative_filter);
      else setPage(1);
    } catch (e) {
      if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
      else toast.error('Something went wrong!');
    } finally {
      setUploadLoading(false);
      setCreativeGenerator(false);
    }
  };

  const onCompleteCanvaDesignsBanners = async (base64s: any[]) => {
    try {
      setUploadLoading(true);
      const formData = new FormData();
      const canvas_banners = base64s.map(base64 => base64.handleDataURL);
      formData.append('body', JSON.stringify({ canvas_banners }));

      await uploadCreatives(formData);

      toast.success('Uploaded Successfully!');
      if (page === 1) getCreatives(1, creative_filter);
      else setPage(1);
    } catch (e) {
      if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
      else toast.error('Something went wrong!');
    } finally {
      setUploadLoading(false);
      setCreativeGenerator(false);
    }
  };

  const updateCreativeAfterEditCreativeName = ({
    creative_uuid,
    name,
  }: Pick<TCreative, 'name' | 'creative_uuid'>) =>
    setCreatives(creatives =>
      creatives.map(creative =>
        creative.creative_uuid === creative_uuid ? { ...creative, name } : { ...creative }
      )
    );

  const updateCreativesListAfterDeletion = useCallback(
    () => getCreatives(page, creative_filter),
    [page, creative_filter]
  );

  const onCloseMagicCreativeGenerator = () => {
    setMagicBannerGeneratorOpen(false);
    clearAllStates();
    onStepChange(steps[0]);
    onSelectGoal(CampaignGoals[0]);
  };

  const onConfirm = () => {
    onCloseMagicCreativeGenerator();
    if (page === 1) getCreatives(1, creative_filter);
    else setPage(1);
  };

  return (
    <div className="creatives-library">
      <PageHeader
        UNSAFE_CLASSNAME="creatives-library__PageHeader"
        LeftContent_ClassName="MyListings__header"
        subTitle={
          <span style={{ marginLeft: '5px' }} className="MyListings__totalCounts">
            You have <b>{pagination.total_count}</b> creatives
          </span>
        }
        actions={[
          <ReactTableSearchBar
            key="search-audiences"
            placeholder="Search creatives by name or ID"
            searchValue={searchValue}
            handleSearchValue={handleSearchValue}
          />,
          <Button
            key="use-ad-builder"
            kind="primary"
            weight="bolder"
            size="l"
            onClick={toggleCreativeGenerator(true)}
            disabled={userIsDeactivatedWithActiveOrderlines}
          >
            Use Ad Builder
          </Button>,
          <div
            key="add-creatives"
            className="add-creatives-toggle-wrapper"
            ref={menuRootWrapperRef}
          >
            <Menu
              id="popup_menu_right"
              className="add-creatives-menu"
              popupAlignment="right"
              popup
              appendTo={() => menuRootWrapperRef.current as HTMLDivElement}
              model={addCreativesMenuItems}
              ref={addCreativesRef}
            />
            <PrimeButton
              className="add-creatives-toggle Button Button--l-size Button--primary Button__link"
              label="Add"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={toggleCreativeTypeSelect}
              aria-controls="popup_menu_left"
              aria-haspopup
              disabled={userIsDeactivatedWithActiveOrderlines}
            />
          </div>,
        ]}
      />
      {creativeGenerator && (
        <Modal offClick={toggleCreativeGenerator(false)}>
          <CreativeGen
            onClose={toggleCreativeGenerator(false)}
            onComplete={onCompleteCreativeGen}
            defaultCreativeType="banner"
            width="95vw"
            height="90vh"
          />
        </Modal>
      )}
      {!!selectedCreativeUploadType && (
        <CreativeCampaignModal
          loading={uploadLoading}
          onClickOutside={() => setSelectedCreativeUploadType(null)}
          type={selectedCreativeUploadType}
          okText="Upload"
          onSubmit={onSubmit}
        />
      )}
      {magicBannerGeneratorOpen && (
        <MagicCreativeGenerator onConfirm={onConfirm} onCancel={onCloseMagicCreativeGenerator} />
      )}
      <CreativesList
        creatives={creatives}
        page={[page, setPage]}
        loading={creativesLoading}
        pagination={[pagination, setPagination]}
        creative_filter={[creative_filter, setCreativeFilter]}
        updateCreativesListAfterDeletion={updateCreativesListAfterDeletion}
        updateCreativeAfterEditCreativeName={updateCreativeAfterEditCreativeName}
        searchValue={searchValue}
      />
      <CanvaDesignsModal
        open={canvaDesignsModalOpen}
        onSubmit={onCompleteCanvaDesignsBanners}
        onCancel={handleCanvaDesignsModalOpen(false)}
      />
    </div>
  );
};

export default CreativesLibrary;
