import React, { useState, useEffect } from 'react';
import type { ReactNode, Key, CSSProperties } from 'react';

import classNames from 'classnames';

import { TableFooter } from '@eltoro-ui/components/TableFooter';
import { TableRow } from '@eltoro-ui/components/TableRow';
import { TableHeader } from '@eltoro-ui/components/TableHeader';

import type { AnyObject } from 'types';

import './Table.scss';

export type GetRowKey<RecordType> = (record: RecordType, index?: number) => Key;
export type RowDisabled<RecordType> = (record: RecordType, index?: number) => boolean;

export type TableColumnType<RecordType> = {
  RowCell?: (row: RecordType) => ReactNode; // Overrides the default row cell row[path] display
  alignment?: 'right' | 'center' | 'left'; // The alignment for the cells in this column
  onSort?: (path: string, sort?: 'asc' | 'desc') => void; // Called when the user clicks the label
  label?: string; // Header label for column
  path: string | string[]; // Object path for display value
  width?: string; // Column width override
};

export type TableType<RecordType> = {
  rows: RecordType[];
  rowKey?: string | keyof RecordType | GetRowKey<RecordType>;
  rowDisabled?: RowDisabled<RecordType>;
  columns: TableColumnType<RecordType>[];

  // For dropdown
  ExpandableRow?: (row: RecordType) => ReactNode;

  // For checkboxes
  onSelect?: (checked: RecordType[]) => void; // Adds item to array, receives checked items

  // Options
  stickyHeader?: boolean;
  FooterContent?: ReactNode;
  striped?: boolean;
  fixedCellSize?: boolean;
  UNSAFE_style?: CSSProperties;
  UNSAFE_className?: string;
  tableHeaderClass?: string;
  tableRowClass?: string;
  checkBorder?: string | undefined;
  checkBoxActive?: string;
  unSelectAll?: boolean;
  noCheckbox?: boolean;
  selectedRows?: RecordType[];
  radioButton?: boolean;
  tableHeaderNoCheckbox?: boolean;
  setUnselectAll?: (val: boolean) => void;
  checkboxRender?: (originalNode: ReactNode, row: RecordType) => ReactNode;
};

export const Table = <RecordType extends AnyObject = AnyObject>({
  rows,
  rowKey = 'id',
  rowDisabled,
  columns,
  stickyHeader,
  FooterContent,
  UNSAFE_style,
  UNSAFE_className,
  striped,
  fixedCellSize,
  ExpandableRow,
  onSelect,
  tableHeaderClass,
  tableRowClass,
  checkBorder,
  checkBoxActive,
  unSelectAll,
  noCheckbox,
  selectedRows,
  tableHeaderNoCheckbox,
  radioButton = false,
  setUnselectAll,
  checkboxRender,
}: TableType<RecordType>) => {
  const [currentSelected, setCurrentSelected] = useState<Key[]>([]);

  useEffect(() => {
    if (selectedRows) setCurrentSelected(selectedRows.map(row => getRowKey(row)));
  }, [selectedRows]);

  const getRowKey = React.useMemo<GetRowKey<RecordType>>(() => {
    if (typeof rowKey === 'function') {
      return rowKey;
    }

    return (record: RecordType) => record?.[rowKey as string];
  }, [rowKey]);

  const formatRows = (toFormatRows?: RecordType[]) =>
    (toFormatRows || rows).map((row, index) => {
      return { id: index, ...row };
    });

  const isChecked = (id: Key) => {
    if (selectedRows) {
      return formatRows(selectedRows).filter(row => getRowKey(row) === id).length > 0;
    }
    return currentSelected.includes(id);
  };

  // Function for individual row selection
  const handleSelectRow = (row: RecordType, checked: boolean) => {
    const key = getRowKey(row);
    let selectedRows: RecordType[] = [];
    let selectedIds: Key[] = [];
    if (!checked) {
      selectedIds = [...currentSelected.filter(item => item !== key)];
      selectedRows = formatRows().filter(row => selectedIds.includes(getRowKey(row)));
    } else if (radioButton) {
      selectedIds = [key];
      selectedRows = formatRows().filter(row => selectedIds.includes(getRowKey(row)));
    } else {
      selectedIds = [...currentSelected, key];
      selectedRows = formatRows().filter(row => selectedIds.includes(getRowKey(row)));
    }
    setCurrentSelected(selectedIds);
    onSelect?.(selectedRows);
  };

  // Function for TableHeader to call when 'select all' checkbox is checked
  const handleSelectAll = (e: any) => {
    if (e) {
      if (currentSelected.length === rows.length) {
        setCurrentSelected([]);
      } else {
        const allRowIds = rows.map(row => getRowKey(row));
        setCurrentSelected(allRowIds);
      }
    }
    if (setUnselectAll && !e) {
      setUnselectAll(true);
    }
  };

  // Determines colspan including all columns + checkboxes + expandable row icon
  const getColspan = () => {
    let num = columns.length;
    if (ExpandableRow) num += 1;
    if (onSelect) num += 1;
    return num;
  };

  useEffect(() => {
    if (unSelectAll) {
      setCurrentSelected([]);
    }
  }, [unSelectAll]);

  return (
    <div className="TableWrapper" style={{ overflow: radioButton ? 'unset' : 'auto' }}>
      <table
        className={`Table ${UNSAFE_className || ''}`}
        style={{
          ...UNSAFE_style,
          tableLayout: fixedCellSize ? 'fixed' : 'auto',
        }}
      >
        <TableHeader
          tableHeaderClass={tableHeaderClass}
          noCheckbox={noCheckbox}
          tableHeaderNoCheckbox={tableHeaderNoCheckbox}
          columns={columns}
          radioButton={radioButton}
          stickyHeader={stickyHeader}
          ExpandableRow={!!ExpandableRow}
          handleSelectAll={onSelect && handleSelectAll}
          selectedAll={onSelect && rows.length > 0 && currentSelected.length === rows.length}
        />
        <tbody className="Table__body">
          {stickyHeader && <tr className="TableRow__spacer" />}
          {formatRows().map((row, index) => {
            const altBg = striped && index % 2 === 1;

            const disabledClassName = classNames({
              TableRow__disabled: rowDisabled && rowDisabled(row),
            });

            return (
              <TableRow
                disabledClassName={disabledClassName}
                key={getRowKey(row) || index}
                row={row}
                columns={columns}
                altBg={altBg}
                radioButton={radioButton}
                striped={striped}
                ExpandableRow={ExpandableRow}
                selected={isChecked(getRowKey(row))}
                handleSelectRow={onSelect && handleSelectRow}
                getColspan={getColspan}
                tableRowClass={tableRowClass}
                checkBorder={checkBorder}
                checkBoxActive={checkBoxActive}
                noCheckbox={noCheckbox}
                checkboxRender={checkboxRender}
              />
            );
          })}
        </tbody>
        {FooterContent && <TableFooter colSpan={getColspan()}>{FooterContent}</TableFooter>}
      </table>
    </div>
  );
};
