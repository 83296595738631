import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import toast from 'react-hot-toast';
import classNames from 'classnames';

import { Modal, Text } from '@eltoro-ui/components';

import { OnboardingModal } from 'Components';
import { VotingModal } from 'Components/VotingModal';
import UpgradeModalForLenses from 'Components/UpgradeModalForLenses';
import CampaignExamplesWidget from 'Components/CampaignExamplesWidget';

import { CampaignProgress } from 'Pages/Dashboard/_Components/CampaignProgress/CampaignProgress';
import { NotificationContainer } from 'Pages/Dashboard/_Components/NotificationContainer/NotificationContainer';
import ViewMarketStats from 'Pages/Dashboard/_Components/ViewMarketActivity/ViewMarketStats';

import { subscribeTopic } from 'Requests';
import { getAllOrderlinesStats } from 'Requests/Request_Methods/campaignMethods';

import Talktoyoursphere from 'assets/Images/talktoyoursphere.png';
import Growyourspehere from 'assets/Images/growyourspehere.png';
import LikelySellersIcon from 'assets/Images/likely-sellers-active.png';
import Humbleberg from 'assets/Images/humbleberg.png';
import Contacticons from 'assets/Images/contacticons.png';
import prospects1Active from 'assets/Images/prospects_lg_1_active.png';
import prospects2 from 'assets/Images/prospects_lg_2.png';
import prospects3 from 'assets/Images/prospects_lg_3.png';
import prospects3_active from 'assets/Images/prospects_lg_3_active.png';
import prospects5 from 'assets/Images/likely-sellers-inactive.png';
import prospects6 from 'assets/Images/prospects_lg_6_active.png';
import digital_farming from 'assets/Images/digital_farming.png';
import Marketacceleration from 'assets/Images/Marketacceleration.png';
import Listingacceleration from 'assets/Images/Listingacceleration.png';
import Activeagents from 'assets/Images/Activeagents.png';
import Newbuyersarea from 'assets/Images/Newbuyersarea.png';

import { UserStatus } from 'enums';
import type { TRootState, AllOrderlinesStats } from 'types';

import './Dashboard.scss';

export const DashboardPage = () => {
  // Modal currently only opens on button click...when we have a global user object can open if user is empty
  const [upgradeForLikelySellers, setUpgradeForLikelySellers] = useState<boolean>(false);
  const [upgradeForGrowYourSphere, setUpgradeForGrowYourSphere] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [TalkModal, setTalkModal] = useState<boolean>(false);
  const [growSphereModal, setGrowSphereModal] = useState<boolean>(false);
  const [likelySellersModal, setLikelySellersModal] = useState(false);
  const [humblebergeModal, setHumblebergeModal] = useState(false);
  const [contactSeenModal, setContactSeenModal] = useState(false);
  const [marketAccModal, setMarketAccModal] = useState(false);
  const [listingAccModal, setListingAccModal] = useState(false);
  const [activeAgentModal, setActiveAgentModal] = useState(false);
  const [newBuyers, setNewBuyers] = useState(false);

  const [statsData, setStatsData] = useState<AllOrderlinesStats | null>(null);

  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const { userNotifications } = useSelector((state: any) => state.notificationsReducer);

  useEffect(() => {
    function completeProfile() {
      if (
        [UserStatus.REGISTERED, UserStatus.COMPLETE, UserStatus.CONFIRMED].includes(userData.status)
      ) {
        setModalOpen(true);
      }
    }

    setTimeout(completeProfile, 0);
  }, [userData]);

  useEffect(() => {
    if (
      [UserStatus.ACTIVE, UserStatus.ON_HOLD].includes(userData.status) ||
      (userData.active_orderline_count &&
        [UserStatus.DEACTIVATED, UserStatus.PAYMENT_FAIL].includes(userData.status))
    ) {
      getAllOrderlinesStats()
        .then(({ data }) => {
          if (data) setStatsData(data);
        })
        .catch(err => {
          if ('detail' in err) toast.error(err.detail);
        });
    }

    return () => {
      setStatsData(null);
    };
  }, []);

  const user = localStorage.getItem('firebase_notification');

  useEffect(() => {
    const subTopic = async (user: string, userNotifications: number) => {
      await subscribeTopic(user, userNotifications);
    };
    if (user && userNotifications) {
      subTopic(user, userNotifications);
    }
  }, [user, userNotifications]);

  return (
    <div className="app_dashboard_container">
      <div className="DashboardPage">
        <CampaignExamplesWidget />
      </div>
      <div className="DashboardPage">
        {modalOpen && <OnboardingModal setModalOpen={setModalOpen} />}
        <div className="DashboardPage__lenses">
          <div className="DashboardPage__topLenses">
            <Link to="/prospects/sell-your-listing-faster" className="DashboardPage__topLensesLink">
              <img src={prospects2} alt="Sell Your Listing Faster" />
              <Text on="white" size="s" UNSAFE_className="DashboardPage__topLensesText">
                Sell Your Listing Faster
              </Text>
            </Link>
          </div>
          <div className="DashboardPage__topLenses">
            <Link to="/prospects/talk-to-your-sphere" className="DashboardPage__topLensesLink">
              <img src={prospects1Active} alt="Talk To Your Sphere" />
              <Text on="white" size="s" UNSAFE_className="DashboardPage__topLensesText">
                Talk To Your Sphere
              </Text>
            </Link>
          </div>
          {TalkModal && (
            <Modal offClick={() => setTalkModal(false)}>
              <VotingModal
                handleModel={() => setTalkModal(false)}
                icons={Talktoyoursphere}
                title="Talk To Your Sphere"
                subTitle="Advertise to your in-market contacts to keep you top of mind."
              />
            </Modal>
          )}
          <div className="DashboardPage__topLenses">
            <Link
              to={userData?.sub_name === 'premium' ? '/prospects/grow-your-sphere' : '/dashboard'}
              onClick={
                userData?.sub_name !== 'premium'
                  ? () => setUpgradeForGrowYourSphere(true)
                  : () => undefined
              }
              className="DashboardPage__topLensesLink"
            >
              <img
                src={userData?.sub_name === 'premium' ? prospects3_active : prospects3}
                alt="Grow Your Sphere"
              />
              <Text
                on="white"
                size="s"
                UNSAFE_className={classNames(
                  'DashboardPage__topLensesText',
                  userData?.sub_name !== 'premium' && 'lens_heading'
                )}
              >
                Grow Your Sphere
              </Text>
            </Link>
          </div>
          {growSphereModal && (
            <Modal offClick={() => setGrowSphereModal(false)}>
              <VotingModal
                handleModel={() => setGrowSphereModal(false)}
                icons={Growyourspehere}
                title="Grow Your Sphere"
                subTitle="Identify and advertise to your contacts’ friends and family to expand your audience."
              />
            </Modal>
          )}
          {upgradeForGrowYourSphere && (
            <UpgradeModalForLenses
              title="Expand Your Sphere"
              description="Upgrade your account to expand your sphere by engaging with households observed at your contact’s homes."
              onCancel={() => setUpgradeForGrowYourSphere(false)}
            />
          )}

          <div className="DashboardPage__topLenses">
            <Link
              to={userData?.sub_name === 'premium' ? '/prospects/likely-sellers' : '/dashboard'}
              onClick={
                userData?.sub_name !== 'premium'
                  ? () => setUpgradeForLikelySellers(true)
                  : () => undefined
              }
              className="DashboardPage__topLensesLink"
            >
              <img
                src={userData?.sub_name === 'premium' ? LikelySellersIcon : prospects5}
                alt="Likely Sellers"
              />
              <Text
                on="white"
                size="s"
                UNSAFE_className={classNames(
                  'DashboardPage__topLensesText',
                  userData?.sub_name !== 'premium' && 'lens_heading'
                )}
              >
                Likely Sellers
              </Text>
            </Link>
          </div>
          {likelySellersModal && (
            <Modal offClick={() => setLikelySellersModal(false)}>
              <VotingModal
                handleModel={() => setLikelySellersModal(false)}
                icons={LikelySellersIcon}
                title="Likely Sellers"
                subTitle="Identify prospects in market that are likely sellers."
              />
            </Modal>
          )}
          {upgradeForLikelySellers && (
            <UpgradeModalForLenses
              title="Identify Potential Sellers"
              description="Upgrade your account to identify households who are likely to sell their house, turning them into potential prospects."
              onCancel={() => setUpgradeForLikelySellers(false)}
            />
          )}
          {likelySellersModal && (
            <Modal offClick={() => setLikelySellersModal(false)}>
              <VotingModal
                handleModel={() => setLikelySellersModal(false)}
                icons={LikelySellersIcon}
                title="Likely Sellers"
                subTitle="Identify prospects in market that are likely sellers."
              />
            </Modal>
          )}
          <div className="DashboardPage__topLenses">
            <Link
              to="/prospects/virtual-just-listed-just-sold"
              className="DashboardPage__topLensesLink"
            >
              <img src={prospects6} alt="" />
              <Text on="white" size="s" UNSAFE_className="DashboardPage__topLensesText">
                Virtual Just Listed/Just Sold
              </Text>
            </Link>
          </div>
          <div className="DashboardPage__topLenses">
            <Link to="/prospects/digital-farming" className="DashboardPage__topLensesLink">
              <img src={digital_farming} alt="digital_farming" />
              <Text on="white" size="s" UNSAFE_className="DashboardPage__topLensesText">
                Digital Farming
              </Text>
            </Link>
          </div>
          {humblebergeModal && (
            <Modal offClick={() => setHumblebergeModal(false)}>
              <VotingModal
                handleModel={() => setHumblebergeModal(false)}
                icons={Humbleberg}
                title="Virtual Just Listed/Just Sold"
                subTitle="Engage with potential sellers by advertising to the neighbors around your listings, active and inactive."
              />
            </Modal>
          )}
        </div>

        {/* {infoStats?.active_campaigns &&
        !Object.entries(infoStats?.active_campaigns).length ? (
          <Loading />
        ) : (
          <div className="DashboardPage__bottom_two_cards_container dahboard-charts">
            {/* <GridBox
              gridTemplateColumns="1fr 1fr "
              gap="1rem"
              paddingTop="1rem"
              UNSAFE_className="dahboard-charts"
              flexGrow={0}
            > */}
        {/* <SavedAudiences
            <SavedAudiences
              data={{
                total: infoStats?.active_campaigns?.total_campaigns,
                used: infoStats?.active_campaigns?.active_campaign,
              }}
              // textTotal="ACTIVE OUT"
              // textShow="# Of Active Campaigns"
              textShow="Campiagn Status"
            /> */}

        {/* <FinishedCampaigns data={infoStats?.finished_campaign} /> */}
      </div>
      <div className="DashboardPage">
        <div className="DashboardPage__top_three_cards_container">
          {/* <div className="col-md-4"> */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridGap: '1rem',
              gap: '1rem',
            }}
          >
            <CampaignProgress
              data={{
                Active: statsData?.Active || 0,
                Completed: statsData?.Completed || 0,
                inReview: statsData?.['In Review'] || 0,
              }}
              textShow="Ad Status"
            />
            {/* <ContactsSeen */}
            {/*  contactsSeenInMarket={ */}
            {/*    statsData?.contacts_seen_in_market as TCampaignsUserStats['contacts_seen_in_market'] */}
            {/*  } */}
            {/* /> */}
          </div>
          {contactSeenModal && (
            <Modal offClick={() => setContactSeenModal(false)}>
              <VotingModal
                handleModel={() => setContactSeenModal(false)}
                icons={Contacticons}
                title="Contacts seen in the Market"
                subTitle="The count of your contacts with a high likelihood to be currently home shopping."
              />
            </Modal>
          )}

          <div className="DashboardPage__activity_list">
            <div className="DashboardPage__activity_components">
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="Market_acceleration_status"
                onClick={() => {
                  setMarketAccModal(true);
                }}
              >
                <ViewMarketStats
                  textValue={5}
                  heading="MARKET ACCELERATION"
                  sub_heading="REGIONAL ACTIVITY"
                />
              </Link>
              {marketAccModal && (
                <Modal offClick={() => setMarketAccModal(false)}>
                  <VotingModal
                    handleModel={() => setMarketAccModal(false)}
                    icons={Marketacceleration}
                    title="Market Acceleration"
                    subTitle="[Percentage] Foot traffic momentum leading into the next reporting period based on all listings in the market."
                  />
                </Modal>
              )}
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="Market_acceleration_status"
                onClick={() => {
                  setListingAccModal(true);
                  // toast.success('This feature is coming soon')
                }}
              >
                <ViewMarketStats
                  textValue={20}
                  heading="LISTING ACCELERATION"
                  sub_heading="REGIONAL ACTIVITY"
                />
              </Link>
              {listingAccModal && (
                <Modal offClick={() => setListingAccModal(false)}>
                  <VotingModal
                    handleModel={() => setListingAccModal(false)}
                    icons={Listingacceleration}
                    title="Listing Acceleration"
                    subTitle="[Percentage] Foot traffic momentum leading into the next reporting period based on user’s active listings."
                  />
                </Modal>
              )}
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="Market_acceleration_status"
                onClick={() => {
                  setActiveAgentModal(true);
                  // toast.success('This feature is coming soon')
                }}
              >
                <ViewMarketStats
                  textValue={-1}
                  heading="ACTIVE AGENTS IN AREA"
                  sub_heading="COMPETITION INDEX"
                />
              </Link>
              {activeAgentModal && (
                <Modal offClick={() => setActiveAgentModal(false)}>
                  <VotingModal
                    handleModel={() => setActiveAgentModal(false)}
                    icons={Activeagents}
                    title="Active Agents in Your Area"
                    subTitle="A distinct count of list/buy agent MLS IDs in the agent’s area."
                  />
                </Modal>
              )}
              <Link
                // to="/prospects/talk-to-your-sphere"
                to="/dashboard"
                className="Market_acceleration_status"
                onClick={() => {
                  setNewBuyers(true);
                  // toast.success('This feature is coming soon')
                }}
              >
                <ViewMarketStats
                  textValue={-9}
                  heading="NEW BUYERS IN AREA"
                  sub_heading="FOOTFALL TRAFFIC"
                />
              </Link>
              {newBuyers && (
                <Modal offClick={() => setNewBuyers(false)}>
                  <VotingModal
                    handleModel={() => setNewBuyers(false)}
                    icons={Newbuyersarea}
                    title="New Buyers in Area"
                    subTitle="Percent of new buyers the user targeted on an ad campaign in the reporting period."
                  />
                </Modal>
              )}
            </div>
          </div>
          <NotificationContainer />
        </div>
      </div>
    </div>
  );
};
