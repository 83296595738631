import React from 'react';
import { Button, FlexBox, Modal } from '@eltoro-ui/components';
import './UsedAudienceModal.scss';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

type UsedAudienceModalProps = {
  campaignId: string;
};
const UsedAudienceModal = ({ campaignId }: UsedAudienceModalProps) => {
  const history = useHistory();
  const onBackClick = () => {
    history.push(`/create-campaign/${campaignId}/setup`);
  };
  return (
    <Modal closable={false} className="used_audience_modal_content">
      <FlexBox UNSAFE_className="modal_content" flexDirection="column" alignItems="center">
        <FlexBox UNSAFE_className="warn_icon" justifyContent="center" alignItems="center">
          <FontAwesomeIcon icon={faExclamationTriangle} color="#FFAB03" fontSize={32} />
        </FlexBox>
        <FlexBox UNSAFE_className="desc_wrapper" flexDirection="column">
          <h3 className="heading">Oops, Active Audiences Detected</h3>
          <p className="desc">
            The audience(s) you selected are now in use by other campaign(s) and will be removed.
            Please return to the Audience step to add or select other available audiences if needed.
          </p>
        </FlexBox>
        <FlexBox>
          <Button kind="primary" size="l" weight="bold" onClick={onBackClick}>
            Back to Audience Step
          </Button>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default UsedAudienceModal;
