import React, { ReactNode, useEffect, useState } from 'react';
import { AudienceStatusEnum, GoalSectionTitles } from 'enums';
import { getCampaignAudiencesByGoal } from 'Requests/Request_Methods/campaignMethods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/pro-solid-svg-icons';
import { TTarget, TTargets } from 'types';
import { Loading, Pagination } from 'Components';
import { FlexBox, Table, Text } from '@eltoro-ui/components';
import { getFileName } from 'Helpers';
import { faBullseye } from '@fortawesome/pro-regular-svg-icons';
import AudienceStatusAccessor from 'Pages/SavedAudiences/AudienceStatusAccessor';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import AudienceEmptyStates from '../AudienceEmptyStates';

type AudienceTableBySelectedGoalProps = {
  goal: GoalSectionTitles;
  campaignId: string | number | undefined;
  selectedAudiences: TTargets;
  onSelect: (audienceList: TTarget[], goal: GoalSectionTitles) => void;
  initialData: {
    campaign_id: string;
    targets: TTargets;
    total: number;
    total_pages: number;
    current_page: number;
  };
};

export default function AudienceTableBySelectedGoal({
  goal,
  campaignId,
  selectedAudiences,
  onSelect,
  initialData,
}: AudienceTableBySelectedGoalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [audiencesByGoal, setAudiencesByGoal] = useState({
    [goal]: initialData,
  });

  const campaignAudiencesesByGoal = (id: string, goal: GoalSectionTitles) => {
    setIsLoading(true);
    getCampaignAudiencesByGoal(goal, id, audiencesByGoal[goal]?.current_page, 10).then(res => {
      if (res.data) {
        setAudiencesByGoal(prevState => {
          if (res?.data) {
            return { ...prevState, [goal]: { ...res?.data } };
          }
          return prevState;
        });
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (campaignId) campaignAudiencesesByGoal(`${campaignId}`, goal);
  }, [audiencesByGoal[goal]?.current_page, campaignId]);

  const content = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (audiencesByGoal[goal].targets?.length) {
      const checkboxRender = (originalNode: ReactNode, row: TTarget) => {
        if (row.status === AudienceStatusEnum.IN_USE) {
          const overlay = (
            <FlexBox flexDirection="column" gap="0.5rem">
              <span>
                This audience is already part of an active ad campaign, {row.campaign_name_in_use},
                and it can't be used in a new campaign.
              </span>
              <span>
                You can either edit the {row.campaign_name_in_use} campaign to add creatives or wait
                for it to finish before sending new ads.
              </span>
            </FlexBox>
          );
          return (
            <Tooltip
              placement="topLeft"
              trigger="hover"
              overlayClassName={classNames('audience-tooltip', 'audience-disable-in_use')}
              showArrow
              align={{
                offset: [-15, -3],
                targetOffset: [-6, 0],
              }}
              overlay={overlay}
            >
              <span>{originalNode}</span>
            </Tooltip>
          );
        }

        if (row.status === AudienceStatusEnum.PENDING)
          return (
            <Tooltip
              placement="topLeft"
              trigger="hover"
              overlayClassName={classNames('audience-tooltip', 'audience-disable-pending')}
              showArrow
              align={{
                offset: [-15, -3],
                targetOffset: [-6, 0],
              }}
              overlay="Your audience creation is in process. Please refresh after a few minutes."
            >
              <span>{originalNode}</span>
            </Tooltip>
          );

        return originalNode;
      };

      return (
        <>
          <Table<TTarget>
            rows={audiencesByGoal[goal].targets}
            rowKey="id"
            rowDisabled={audience => audience.status !== AudienceStatusEnum.ACTIVE}
            tableRowClass="CampaignSetup__tableRow"
            checkBorder="1px solid black"
            checkBoxActive="CampaignSetup__tableCheckbox"
            selectedRows={selectedAudiences}
            checkboxRender={checkboxRender}
            tableHeaderNoCheckbox
            fixedCellSize
            onSelect={audienceList => onSelect(audienceList, goal)}
            columns={[
              {
                path: 'source',
                label: ' ',
                RowCell: audience => (
                  <FlexBox justifyContent="center" alignItems="center">
                    <FontAwesomeIcon
                      icon={faBullseye}
                      color={
                        audience.status !== AudienceStatusEnum.ACTIVE
                          ? '#D1D1D1'
                          : 'var(--color-secondary-300)'
                      }
                      className="table-audience-icon-small"
                    />
                  </FlexBox>
                ),
                width: '60px',
              },
              {
                path: 'name',
                label: ' ',
                RowCell: audience => (
                  <Text
                    on="white"
                    size="m"
                    weight="bold"
                    tag="div"
                    UNSAFE_className="table-audience-name"
                  >
                    {audience.type === 'prospects' ? getFileName(audience.name) : audience.name}
                    <br />
                    <p
                      style={{
                        fontWeight: 300,
                        fontSize: '14px',
                        margin: '0',
                        color: '#6D6D6D',
                        textTransform: 'capitalize',
                      }}
                    >
                      {audience.source}
                    </p>
                  </Text>
                ),
              },
              {
                path: 'status',
                label: ' ',
                width: '180px',
                RowCell: audience =>
                  audience.status ? <AudienceStatusAccessor status={audience.status} /> : '-',
              },
              {
                path: 'prospects_counts',
                label: ' ',
                width: '180px',
                RowCell: audience => (
                  <FlexBox alignItems="center" gap="10px" justifyContent="space-between">
                    <Text on="white" size="l" weight="bold" UNSAFE_className="prospects_count">
                      <FontAwesomeIcon
                        icon={faWalking}
                        color={
                          audience.status !== AudienceStatusEnum.ACTIVE
                            ? '#D1D1D1'
                            : 'var(--color-secondary-300)'
                        }
                      />{' '}
                      {audience.prospects_counts || '-'}
                    </Text>
                    <span className="prospect-info">PROSPECTS</span>
                  </FlexBox>
                ),
              },
            ]}
          />
          {audiencesByGoal[goal]?.total > 10 && (
            <Pagination
              title="audiences"
              currentPage={audiencesByGoal[goal]?.current_page}
              loading={isLoading}
              totalItems={audiencesByGoal[goal]?.total}
              totalPages={audiencesByGoal[goal]?.total_pages}
              rangeOfItems={10}
              onClickPrev={() =>
                setAudiencesByGoal(prev => {
                  return {
                    ...prev,
                    [goal]: {
                      ...prev[goal],
                      current_page: prev[goal].current_page - 1,
                    },
                  };
                })
              }
              onClickNext={() =>
                setAudiencesByGoal(prev => {
                  return {
                    ...prev,
                    [goal]: {
                      ...prev[goal],
                      current_page: prev[goal].current_page + 1,
                    },
                  };
                })
              }
            />
          )}
        </>
      );
    }
    return <AudienceEmptyStates goal={goal} />;
  };

  return <div>{content()}</div>;
}
