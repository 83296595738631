import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import { MaxHeightContainer, LargeIconButton, Text, GridBox, Modal } from '@eltoro-ui/components';
import { VotingModal } from 'Components/VotingModal';
import UpgradeModalForLenses from 'Components/UpgradeModalForLenses';
import CampaignExamplesWidget from 'Components/CampaignExamplesWidget';

import { Layout } from 'Components';

import prospect_1 from 'assets/Images/prospects_lg_1_active.png';
import prospect_2 from 'assets/Images/prospects_lg_2.png';
import digital_farming from 'assets/Images/digital_farming.png';
import prospect_3 from 'assets/Images/prospects_lg_3.png';
import prospect_3_active from 'assets/Images/prospects_lg_3_active.png';
import prospect_5 from 'assets/Images/likely-sellers-inactive.png';
import prospect_6 from 'assets/Images/prospects_lg_6_active.png';
import Talktoyoursphere from 'assets/Images/talktoyoursphere.png';
import Growyourspehere from 'assets/Images/growyourspehere.png';
import LikelySellersIcon from 'assets/Images/likely-sellers-active.png';
import Humbleberg from 'assets/Images/humbleberg.png';

import type { TRootState } from 'types';

import './ProspectFinderSelection.scss';

const ProspectFinderHeader: React.FC<{
  mainTitle: string;
  subTitle?: string;
  icon?: JSX.Element;
}> = ({ mainTitle, subTitle, icon }) => {
  return (
    <div className="ProspectFinderHeader">
      <GridBox alignItems="center" gridTemplateColumns="auto 1fr" gap="1rem">
        {icon && (
          <Text on="white" size="xxl">
            {icon}
          </Text>
        )}
        <Text on="white" size="xxxl" weight="bold" UNSAFE_className="ProspectFinderHeader__title">
          {mainTitle}
        </Text>
      </GridBox>
      {subTitle && <Text on="white">{subTitle}</Text>}
    </div>
  );
};

export const ProspectFinderSelection = () => {
  const { url } = useRouteMatch();
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  const [upgradeForLikelySellers, setUpgradeForLikelySellers] = useState<boolean>(false);
  const [upgradeForGrowYourSphere, setUpgradeForGrowYourSphere] = useState<boolean>(false);
  const [TalkModal, setTalkModal] = useState<boolean>(false);
  const [growSphereModal, setGrowSphereModal] = useState<boolean>(false);
  const [likelySellersModal, setLikelySellersModal] = useState(false);
  const [humblebergeModal, setHumblebergeModal] = useState(false);

  return (
    <div className="ProspectFinderSelection" data-testid="prospectActivityPage">
      <MaxHeightContainer>
        <Layout container_className="ProspectFinderSelection__layout">
          <div className="ProspectFinderSelection__content">
            <CampaignExamplesWidget />
            <div className="ProspectFinderSelection__contentHeader">
              <ProspectFinderHeader mainTitle="" />
              <p className="ProspectFinderSelection__sub_title">
                Identify prospects by observing footfall traffic seen in market or other variables
              </p>
            </div>
            <div className="ProspectFinderSelection__section ProspectFinderSelection__wrap">
              <LargeIconButton
                title="Sell Your Listing Faster"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={prospect_2}
                    alt="prospect_2"
                  />
                }
                subTitle="Locate intendeds seen at comparable properties."
                to={`${url}/sell-your-listing-faster`}
              />
              <LargeIconButton
                title="Talk To Your Sphere"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={prospect_1}
                    alt="prospect_1"
                  />
                }
                subTitle="Stay in contact with your contacts."
                to={`${url}/talk-to-your-sphere`}
              />
              {TalkModal && (
                <Modal offClick={() => setTalkModal(false)}>
                  <VotingModal
                    handleModel={() => setTalkModal(false)}
                    icons={Talktoyoursphere}
                    title="Talk To Your Sphere"
                    subTitle="Advertise to your in-market contacts to keep you top of mind."
                  />
                </Modal>
              )}
              <LargeIconButton
                UNSAFE_className={classnames(
                  userData?.sub_name !== 'premium' && 'prospects_heading'
                )}
                title="Grow Your Sphere"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={userData?.sub_name === 'premium' ? prospect_3_active : prospect_3}
                    alt="Grow Your Sphere"
                  />
                }
                subTitle="Expand your sphere by engaging with households observed at your contact’s homes."
                onClick={
                  userData?.sub_name !== 'premium'
                    ? () => setUpgradeForGrowYourSphere(true)
                    : () => undefined
                }
                to={userData?.sub_name === 'premium' ? `${url}/grow-your-sphere` : undefined}
              />
              {growSphereModal && (
                <Modal offClick={() => setGrowSphereModal(false)}>
                  <VotingModal
                    handleModel={() => setGrowSphereModal(false)}
                    icons={Growyourspehere}
                    title="Grow Your Sphere"
                    subTitle="Identify and advertise to your contacts’ friends and family to expand your audience."
                  />
                </Modal>
              )}
              {upgradeForGrowYourSphere && (
                <UpgradeModalForLenses
                  title="Expand Your Sphere"
                  description="Upgrade your account to expand your sphere by engaging with households observed at your contact’s homes."
                  onCancel={() => setUpgradeForGrowYourSphere(false)}
                />
              )}
              <LargeIconButton
                UNSAFE_className={classnames(
                  userData?.sub_name !== 'premium' && 'prospects_heading'
                )}
                title="Likely Sellers"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={userData?.sub_name === 'premium' ? LikelySellersIcon : prospect_5}
                    alt="Likely Sellers"
                  />
                }
                subTitle="Expand your marketing efforts by reaching out to prospects within an area."
                onClick={
                  userData?.sub_name !== 'premium'
                    ? () => setUpgradeForLikelySellers(true)
                    : () => undefined
                }
                to={userData?.sub_name === 'premium' ? `${url}/likely-sellers` : undefined}
              />
              {likelySellersModal && (
                <Modal offClick={() => setLikelySellersModal(false)}>
                  <VotingModal
                    handleModel={() => setLikelySellersModal(false)}
                    icons={LikelySellersIcon}
                    title="Likely Sellers"
                    subTitle="Identify prospects in market that are likely sellers."
                  />
                </Modal>
              )}
              {upgradeForLikelySellers && (
                <UpgradeModalForLenses
                  title="Identify Potential Sellers"
                  description="Upgrade your account to identify households who are likely to sell their house, turning them into potential prospects."
                  onCancel={() => setUpgradeForLikelySellers(false)}
                />
              )}
              <LargeIconButton
                title="Virtual Just Listed/Just Sold"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={prospect_6}
                    alt="prospect_4"
                  />
                }
                to="/prospects/virtual-just-listed-just-sold"
                subTitle="Engage with potential sellers by advertising to the neighbors around your listings."
              />
              {humblebergeModal && (
                <Modal offClick={() => setHumblebergeModal(false)}>
                  <VotingModal
                    handleModel={() => setHumblebergeModal(false)}
                    icons={Humbleberg}
                    title="Virtual Just Listed/Just Sold"
                    subTitle="Engage with potential sellers by advertising to the neighbors around your listings, active and inactive."
                  />
                </Modal>
              )}
              <LargeIconButton
                title="Digital Farming"
                icon={
                  <img
                    className="ProspectFinderSelection__prospect_logo"
                    src={digital_farming}
                    alt="digital_farming"
                  />
                }
                subTitle="Expand your marketing efforts by reaching out to prospects within new areas."
                to={`${url}/digital-farming`}
              />
            </div>
          </div>
        </Layout>
      </MaxHeightContainer>
    </div>
  );
};
