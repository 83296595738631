/* eslint-disable */
import React, { Children, cloneElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RCTooltip from 'rc-tooltip';
import classnames from 'classnames';
import toast from 'react-hot-toast';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { MultiSelect } from 'primereact/multiselect';
import type { MultiSelectChangeEvent } from 'primereact/multiselect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { useForm } from '@eltoro-ui/hooks';

import { Button, FlexBox, Form, Text } from '@eltoro-ui/components';
import { ErrorMsg, Tooltip, Spinner } from 'Components';
import { LabelInputField } from 'Components/LabelInputField';
import { LabelInputSelect } from 'Components/LabelInputSelect';

import { createChargebeeUser, userUpdate } from 'Requests';
import { getMlsServiceNames } from 'Requests/Request_Methods/listingMethods';
import { setSpinner } from 'Redux/actions';

import type { TRootState, UserInfoType, ErrorResponse, FieldErrors, MLSSystemData } from 'types';

import Warning from 'Images/warning.png';
import cross from 'assets/Images/cross.png';

import USStates from 'Utils/us_states.json';

import './PersonalInfoForm.scss';

export const PersonalInfoForm = ({
  onSubmit,
  user,
}: {
  onSubmit: (value: UserInfoType['personalInfo']) => void;
  user: UserInfoType;
}) => {
  const dispatch = useDispatch();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const [mlsServiceNamesLoading, setMlsServiceNamesLoading] = useState(false);
  const [mlsServiceNames, setMlsServiceNames] = useState<string[]>([]);
  const [selectedMLSSystems, setSelectedMLSSystems] = useState<MLSSystemData[]>([]);

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidPrimaryZipCode, setIsValidPrimaryZipCode] = useState(true);
  const [isValidZipCode, setIsValidZipCode] = useState(true);

  const getServiceNames = async () => {
    try {
      setMlsServiceNamesLoading(true);
      const { data } = await getMlsServiceNames();

      if (data) setMlsServiceNames(data.mls_service_names);
    } catch (e) {
      console.warn(e);
    } finally {
      setMlsServiceNamesLoading(false);
    }
  };

  useEffect(() => {
    getServiceNames();
  }, []);

  const required = [
    'first_name',
    'last_name',
    'phone_number',
    'brokerage',
    'email',
    'state',
    'street',
    'city',
    'billing_zip_code',
  ];
  const [isSubmit, setIsSubmit] = useState(false);

  // --------- Personal Information ---------
  const [firstName, setFistName] = useState(
    user.personalInfo.first_name ? user.personalInfo.first_name : ''
  );
  const [lastName, setLastName] = useState(
    user.personalInfo.last_name ? user.personalInfo.last_name : ''
  );
  const [mlsRegisteredName, setMlsRegisteredName] = useState<string>(
    user.personalInfo.mls_registered_name || ''
  );
  const [mlsAgentID, setMlsAgentID] = useState<string>(user.personalInfo.mls_id || '');
  const [email] = useState<string>(
    user.personalInfo.email || localStorage.getItem('userMail') || ''
  );
  const [primaryZipCode, setPrimaryZipCode] = useState<string>(
    user.personalInfo.primary_zip_code || ''
  );
  const [brokerage, setBrokerage] = useState<string>(user.personalInfo.brokerage || '');
  const [phoneNumber, setPhoneNumber] = useState<string>(user.personalInfo.phone_number || '');

  // ------------ Billing Information -------------

  const [street, setStreet] = useState(user.personalInfo ? user.personalInfo.street : '');
  const [city, setCity] = useState(user.personalInfo.last_name ? user.personalInfo.city : '');

  const [usState, setUsState] = useState(user.personalInfo.state ? user.personalInfo.state : '');
  const [zipCode, setZipCode] = useState(
    user.personalInfo.billing_zip_code ? user.personalInfo.billing_zip_code : ''
  );

  const [errors, setErrors] = useState<FieldErrors<UserInfoType['personalInfo']>>({});

  const { formData, touched, isValidForm } = useForm<UserInfoType['personalInfo']>({
    brokerage,
    first_name: firstName.trim(),
    last_name: lastName.trim(),
    mls_id: mlsAgentID.trim(),
    mls_registered_name: mlsRegisteredName.trim(),
    phone_number: phoneNumber.trim(),
    email: email.trim(),
    primary_zip_code: primaryZipCode,
    street: street.trim(),
    city: city.trim(),
    state: usState,
    billing_zip_code: zipCode,
    mls_data: selectedMLSSystems.map(mls => ({
      mls_service_name: mls.mls_service_name,
      mls_id: mls.mls_id.trim(),
    })),
  });
  const { validForm, missingFields } = isValidForm([
    ...required,
    isValidPhoneNumber,
    isValidZipCode,
    isValidPrimaryZipCode,
  ]);
  const validateZipCode = (zipCode: string) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

  const [resErrorPhoneNumber, setResErrorPhoneNumber] = useState('');

  const { spinner } = useSelector((state: TRootState) => state.SpinnerReducer);

  const verifySubmit = async (data: UserInfoType['personalInfo']) => {
    const number = phoneUtil.parseAndKeepRawInput(
      phoneNumber.length >= 1
        ? phoneNumber
        : user.personalInfo.phone_number.length >= 1
        ? user.personalInfo.phone_number
        : '',
      'US'
    );

    const postalCode =
      primaryZipCode.length >= 1
        ? primaryZipCode
        : user.personalInfo.primary_zip_code.length >= 1
        ? user.personalInfo.primary_zip_code
        : '';

    const billingZipCode =
      zipCode.length >= 1
        ? zipCode
        : user.personalInfo.billing_zip_code.length >= 1
        ? user.personalInfo.billing_zip_code
        : '';

    const isValidFirstName = firstName.length >= 1 || user.personalInfo.first_name.length >= 1;
    const isValidLastName = lastName.length >= 1 || user.personalInfo.last_name.length >= 1;

    const isValidBrokerage = brokerage.length >= 1 || user.personalInfo.brokerage.length >= 1;
    const isValidStreet = street.length >= 1 || user.personalInfo.street.length >= 1;
    const isValidState = usState.length >= 1 || user.personalInfo.state.length >= 1;
    const isValidCity = city.length >= 1 || user.personalInfo.city.length >= 1;

    const isValidMLSSystems =
      selectedMLSSystems.length && selectedMLSSystems.every(({ mls_id }) => mls_id);

    const isValidPostalCode = postalCode ? validateZipCode(postalCode) : true;

    const isValidBillingZipCode = validateZipCode(billingZipCode);
    const isValidPhoneNumber = phoneUtil.isValidNumberForRegion(number, 'US');
    const hasErrors = Object.keys(errors).length;

    if (
      isValidFirstName &&
      isValidLastName &&
      isValidPhoneNumber &&
      isValidBrokerage &&
      isValidPostalCode &&
      isValidZipCode &&
      isValidMLSSystems &&
      isValidStreet &&
      isValidState &&
      isValidCity &&
      isValidBillingZipCode &&
      !hasErrors
    ) {
      dispatch(setSpinner(true));

      userUpdate(data)
        .then(res => {
          const schema = {
            id: res.data?.id,
            first_name: res.data?.first_name,
            last_name: res.data?.last_name,
            email: res.data?.email,
          };

          createChargebeeUser(schema).catch(err => {
            toast.error(err.detail);
          });
          onSubmit(data);
          dispatch(setSpinner(false));
        })
        .catch((err: ErrorResponse<UserInfoType['personalInfo']>) => {
          dispatch(setSpinner(false));
          if (Array.isArray(err.detail))
            err.detail.map(error =>
              setErrors(errors => ({
                ...errors,
                [error.loc[1]]: {
                  type: error.type,
                  message: error.msg,
                },
              }))
            );
          else toast.error(err.detail);
        });
    } else {
      setIsSubmit(true);
    }
  };

  const closedSection = () => {
    window.localStorage.clear();
    location.reload();
  };
  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          text="Please fill the input"
          topAbsolute="-80px"
          rightAbsolute="-43px"
          padding="5px 5px"
        />
      </div>
    );
  };

  return (
    <FlexBox
      flexDirection="column"
      gap="2rem"
      UNSAFE_className={classnames('PersonalInfoForm', 'mls-system-form')}
    >
      <img src={cross} onClick={closedSection} alt="cross" className="PersonalInfoForm__cross" />
      {(spinner || mlsServiceNamesLoading) && <Spinner />}
      <FlexBox
        flexDirection="column"
        alignItems="center"
        gap="8px"
        UNSAFE_className="PersonalInfoForm__description"
      >
        <span className="heading">General Info</span>
        <span className="sub_heading">
          We’re here to get you the most tailored experience. All we need from you is to answer a
          couple of simple questions.
        </span>
      </FlexBox>
      <div className="PersonalInfoForm__inputs">
        <Form
          onSubmit={() => {
            verifySubmit(formData);
          }}
          required={required}
          valid={validForm && !Object.keys(errors).length}
          missingFields={missingFields}
          touched={touched}
          UNSAFE_className="personal-information-form"
        >
          <div className="form-section">
            <span className="form-section-title">Personal Information</span>
            <div className="form-section-content">
              <div className={classnames('inputs-wrapper', 'mls-system-inputs')}>
                {/* First Name  */}
                <div className="input-wrapper">
                  <LabelInputField
                    style={{ paddingLeft: '22px' }}
                    lableStyle={{ left: '14px' }}
                    requireTage="*"
                    value={firstName}
                    title="First Name"
                    placeholder="Enter your first name"
                    isError={isSubmit && firstName.length <= 1}
                    type="text"
                    iconRight={
                      isSubmit && firstName.length <= 1 ? (
                        <img
                          alt="icon"
                          className="start_icon"
                          style={{ marginTop: '7px', width: '24px' }}
                          src={Warning}
                        />
                      ) : null
                    }
                    onChange={e => {
                      if (errors?.first_name)
                        setErrors(({ first_name, ...errors }) => ({ ...errors }));
                      setFistName(e.target.value);
                    }}
                  />
                  {isSubmit && firstName.length === 0 ? <ErrorToolbar /> : ''}
                  {errors?.first_name?.message ? (
                    <ErrorMsg
                      title={errors?.first_name?.message ?? 'Please enter your first name'}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  ) : null}
                </div>

                {/* Last Name  */}
                <div className="input-wrapper">
                  <LabelInputField
                    style={{ paddingLeft: '22px' }}
                    lableStyle={{ left: '14px' }}
                    requireTage="*"
                    value={lastName}
                    title="Last Name"
                    placeholder="Enter your last name"
                    isError={isSubmit && lastName.length <= 1}
                    type="text"
                    iconRight={
                      isSubmit && lastName.length <= 1 ? (
                        <img
                          alt="icon"
                          className="start_icon"
                          style={{ marginTop: '7px', width: '24px' }}
                          src={Warning}
                        />
                      ) : null
                    }
                    onChange={e => {
                      if (errors?.last_name)
                        setErrors(({ last_name, ...errors }) => ({ ...errors }));
                      setLastName(e.target.value);
                    }}
                  />
                  {isSubmit && lastName.length === 0 ? <ErrorToolbar /> : ''}
                  {errors?.last_name?.message ? (
                    <ErrorMsg
                      title={errors?.last_name?.message ?? 'Please enter your last name'}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  ) : null}
                </div>

                {/* Work Email  */}
                <div className="input-wrapper">
                  <LabelInputField
                    requireTage="*"
                    style={{ paddingLeft: '22px' }}
                    lableStyle={{ left: '14px' }}
                    value={user.personalInfo.email ? user.personalInfo.email : email}
                    title="Work Email"
                    placeholder="Work Email"
                    isError={isSubmit && email.length < 1}
                    disabled={true}
                    type="text"
                  />
                  {isSubmit && email.length < 1 ? <ErrorToolbar /> : ''}
                </div>

                {/* Phone Number  */}
                <div className="input-wrapper">
                  <LabelInputField
                    style={{ paddingLeft: '22px' }}
                    lableStyle={{ left: '14px' }}
                    requireTage="*"
                    value={phoneNumber}
                    title="Phone Number"
                    placeholder="e.g. 234567890987"
                    isError={isSubmit && phoneNumber.length < 1}
                    type="text"
                    iconRight={
                      isSubmit && phoneNumber.length < 1 ? (
                        <img
                          alt="icon"
                          className="start_icon"
                          style={{ marginTop: '7px', width: '24px' }}
                          src={Warning}
                        />
                      ) : null
                    }
                    onChange={e => {
                      if (errors?.phone_number)
                        setErrors(({ phone_number, ...errors }) => ({ ...errors }));
                      resErrorPhoneNumber && setResErrorPhoneNumber('');
                      const value = e.target.value.replace(/[^0-9() -]+/g, '');
                      setPhoneNumber(value);

                      if (value) {
                        try {
                          const number = phoneUtil.parseAndKeepRawInput(value, 'US');
                          setIsValidPhoneNumber(phoneUtil.isValidNumberForRegion(number, 'US'));
                        } catch (e) {
                          setIsValidPhoneNumber(false);
                        }
                      }
                    }}
                  />
                  {isSubmit && phoneNumber.length < 1 ? <ErrorToolbar /> : ''}
                  {errors?.phone_number?.message || (!isValidPhoneNumber && phoneNumber !== '') ? (
                    <ErrorMsg
                      title={errors?.phone_number?.message ?? 'Invalid phone number!'}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  ) : null}
                </div>

                {/* Brokerage  */}
                <div className="input-wrapper">
                  <LabelInputField
                    requireTage="*"
                    style={{ paddingLeft: '22px' }}
                    lableStyle={{ left: '14px' }}
                    value={brokerage}
                    title="Your Brokerage"
                    placeholder="Enter your brokerage"
                    isError={isSubmit && !brokerage}
                    type="text"
                    iconRight={
                      isSubmit && !brokerage ? (
                        <img
                          alt="icon"
                          className="start_icon"
                          style={{ marginTop: '7px', width: '24px' }}
                          src={Warning}
                        />
                      ) : null
                    }
                    onChange={e => {
                      if (errors?.brokerage)
                        setErrors(({ brokerage, ...errors }) => ({ ...errors }));
                      setBrokerage(e.target.value);
                    }}
                  />
                  {isSubmit && brokerage.length < 1 ? <ErrorToolbar /> : ''}
                  {errors?.brokerage?.message ? (
                    <ErrorMsg
                      title={errors?.brokerage?.message ?? 'Enter your brokerage'}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  ) : null}
                </div>
              </div>
              <FlexBox flexDirection="column" gap="2rem">
                <div className="mls-selection-container">
                  <MultiSelect
                    filter
                    value={selectedMLSSystems.map(({ mls_service_name }) => mls_service_name)}
                    onChange={(e: MultiSelectChangeEvent) => {
                      setSelectedMLSSystems(selectedSystems =>
                        e.value.map((mls_service_name: string) => {
                          const selectedSystem = selectedSystems.find(
                            system => system.mls_service_name === mls_service_name
                          );

                          return (
                            selectedSystem || {
                              mls_service_name,
                              mls_id: '',
                            }
                          );
                        })
                      );
                    }}
                    options={mlsServiceNames}
                    placeholder="Select MLS"
                    filterPlaceholder="Search for MLS"
                    fixedPlaceholder
                    closeIcon={null}
                    appendTo="self"
                    required
                    resetFilterOnHide
                    checkboxIcon={<FontAwesomeIcon icon={faCheck} color="#FFAB03" size="xs" />}
                    emptyFilterMessage={props => {
                      // @ts-ignore
                      const filterValue = props.metaData.state.filterState;
                      return (
                        <FlexBox alignItems="center" justifyContent="space-between">
                          <span>Nothing Found for “{filterValue}”</span>
                          <Button
                            size="m"
                            kind="text"
                            UNSAFE_style={{ fontSize: '14px' }}
                            onClick={() =>
                              setSelectedMLSSystems(systems => [
                                ...systems,
                                { mls_service_name: filterValue, mls_id: '' },
                              ])
                            }
                            UNSAFE_className="refresh-contacts"
                          >
                            Add as New
                          </Button>
                        </FlexBox>
                      );
                    }}
                    panelHeaderTemplate={({ checkboxElement, filterElement, className }) => {
                      const checkbox = cloneElement(
                        checkboxElement,
                        checkboxElement.props,
                        Children.map(checkboxElement.props.children, child => {
                          if (!child) return child;
                          const props = { ...child.props };
                          const icon = <FontAwesomeIcon icon={faCheck} color="#FFAB03" size="xs" />;
                          return cloneElement(child, { ...props, icon });
                        })
                      );
                      return (
                        <FlexBox UNSAFE_className={className}>
                          {checkbox}
                          {filterElement}
                        </FlexBox>
                      );
                    }}
                  />
                  {selectedMLSSystems.map(system => (
                    <FlexBox
                      key={system.mls_service_name}
                      alignItems="center"
                      gap="1.5rem"
                      UNSAFE_className="mls-system-fields-container"
                    >
                      <div className="mls-name-zip-container">
                        {/* MLS System Name  */}
                        <div className="input-wrapper mls-system-name">
                          <LabelInputField
                            style={{ paddingLeft: '22px' }}
                            lableStyle={{ left: '14px' }}
                            requireTage="*"
                            value={system.mls_service_name}
                            title="MLS"
                            placeholder="MLS"
                            type="text"
                            disabled={true}
                          />
                        </div>

                        {/* MLS ID  */}
                        <div className="input-wrapper">
                          <LabelInputField
                            requireTage="*"
                            style={{ paddingLeft: '22px' }}
                            lableStyle={{ left: '14px' }}
                            value={system.mls_id}
                            title="Enter MLS ID"
                            placeholder="e.g. 1223456789"
                            isError={isSubmit && !system.mls_id.trim()}
                            type="text"
                            iconRight={
                              isSubmit && !system.mls_id ? (
                                <img
                                  alt="icon"
                                  className="start_icon"
                                  style={{ marginTop: '7px', width: '24px' }}
                                  src={Warning}
                                />
                              ) : null
                            }
                            onChange={e => {
                              setSelectedMLSSystems(systems =>
                                systems.map(selectedSystem => {
                                  if (selectedSystem.mls_service_name === system.mls_service_name)
                                    return { ...selectedSystem, mls_id: e.target.value };
                                  return selectedSystem;
                                })
                              );
                            }}
                          />
                          {isSubmit && system.mls_id.trim().length < 1 ? <ErrorToolbar /> : ''}
                        </div>
                      </div>
                      <button
                        className="delete-mls-system"
                        onClick={() =>
                          setSelectedMLSSystems(systems =>
                            systems.filter(
                              ({ mls_service_name }) => mls_service_name !== system.mls_service_name
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          color="#9A9A9A"
                          style={{ fontSize: 24 }}
                        />
                      </button>
                    </FlexBox>
                  ))}
                </div>
                <div className="mls-name-zip-container">
                  {/* MLS Registered Name  */}
                  <div className="input-wrapper">
                    <LabelInputField
                      style={{ paddingLeft: '22px' }}
                      lableStyle={{ left: '14px' }}
                      value={mlsRegisteredName}
                      title="Your MLS Registered Name"
                      placeholder="Enter your MLS registered name"
                      type="text"
                      onChange={e => {
                        if (errors?.mls_registered_name)
                          setErrors(({ mls_registered_name, ...errors }) => ({ ...errors }));
                        setMlsRegisteredName(e.target.value);
                      }}
                    />
                  </div>

                  {/* Zip Code  */}
                  <div className="input-wrapper">
                    <LabelInputField
                      style={{ paddingLeft: '22px' }}
                      lableStyle={{ left: '14px' }}
                      value={primaryZipCode}
                      title="Area Zip Code"
                      placeholder="e.g. 12345"
                      type="text"
                      onChange={e => {
                        if (errors?.primary_zip_code)
                          setErrors(({ primary_zip_code, ...errors }) => ({ ...errors }));
                        setPrimaryZipCode(e.target.value);
                        setIsValidPrimaryZipCode(
                          e.target.value ? validateZipCode(e.target.value) : true
                        );
                      }}
                      iconRight={
                        <RCTooltip
                          placement="topRight"
                          trigger="hover"
                          overlayClassName={classnames(
                            'audience-tooltip',
                            'use-audience-prospects'
                          )}
                          showArrow
                          align={{
                            offset: [10, -4],
                            targetOffset: [-6, 0],
                          }}
                          overlay="Please provide us with one ZIP code for a listing you have or have had in the past. We use this information as a backup method to identify your listing from the MLS."
                        >
                          <span style={{ cursor: 'hover', display: 'flex' }}>
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              style={{ fontSize: 20, color: '#979797' }}
                            />
                          </span>
                        </RCTooltip>
                      }
                    />
                    {primaryZipCode && !isValidPrimaryZipCode ? (
                      <ErrorMsg
                        title={errors?.primary_zip_code?.message ?? 'Invalid zip code!'}
                        icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                      />
                    ) : null}
                  </div>
                </div>
              </FlexBox>
            </div>
          </div>
          <div className="form-section">
            <span className="form-section-title">Address</span>
            <div className="form-section-content">
              <div className={classnames('inputs-wrapper', 'mls-system-inputs')}>
                {/* Street */}
                <div className="input-wrapper">
                  <LabelInputField
                    style={{ paddingLeft: '22px' }}
                    lableStyle={{ left: '14px' }}
                    requireTage="*"
                    value={street}
                    title="Street"
                    placeholder="Enter your street name"
                    isError={isSubmit && street.length <= 1}
                    type="text"
                    iconRight={
                      isSubmit && street.length <= 1 ? (
                        <img
                          alt="icon"
                          className="start_icon"
                          style={{ marginTop: '7px', width: '24px' }}
                          src={Warning}
                        />
                      ) : null
                    }
                    onChange={e => {
                      if (errors?.street) setErrors(({ street, ...errors }) => ({ ...errors }));
                      setStreet(e.target.value);
                    }}
                  />
                  {isSubmit && street.length === 0 ? <ErrorToolbar /> : ''}
                  {errors?.street?.message ? (
                    <ErrorMsg
                      title={errors?.street?.message ?? 'Please enter your street name'}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  ) : null}
                </div>

                {/* State  */}
                <div className="input-wrapper">
                  <LabelInputSelect
                    requireTage="*"
                    value={usState}
                    title="Choose State"
                    placeholder="Choose State"
                    onChange={event => setUsState(event.target.value)}
                    Options={
                      <>
                        {USStates.map(state => (
                          <React.Fragment key={state}>
                            <option value={state}>{state}</option>
                          </React.Fragment>
                        ))}
                      </>
                    }
                  />
                  {errors?.state?.message ? (
                    <ErrorMsg
                      title={errors?.state?.message ?? 'Please choose your state'}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  ) : null}
                </div>

                {/* City  */}
                <div className="input-wrapper">
                  <LabelInputField
                    style={{ paddingLeft: '22px' }}
                    lableStyle={{ left: '14px' }}
                    requireTage="*"
                    value={city}
                    title="City"
                    placeholder="Enter your city name"
                    isError={isSubmit && city.length <= 1}
                    type="text"
                    iconRight={
                      isSubmit && city.length <= 1 ? (
                        <img
                          alt="icon"
                          className="start_icon"
                          style={{ marginTop: '7px', width: '24px' }}
                          src={Warning}
                        />
                      ) : null
                    }
                    onChange={e => {
                      if (errors?.city) setErrors(({ city, ...errors }) => ({ ...errors }));
                      setCity(e.target.value);
                    }}
                  />
                  {isSubmit && city.length === 0 ? <ErrorToolbar /> : ''}
                  {errors?.city?.message ? (
                    <ErrorMsg
                      title={errors?.city?.message ?? 'Please enter your city name'}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  ) : null}
                </div>
                {/* Zip Code  */}
                <div className="input-wrapper">
                  <LabelInputField
                    requireTage="*"
                    style={{ paddingLeft: '22px' }}
                    lableStyle={{ left: '14px' }}
                    value={zipCode}
                    title="Zip Code"
                    placeholder="e.g. 45678"
                    isError={isSubmit && !zipCode}
                    type="text"
                    iconRight={
                      isSubmit && !zipCode ? (
                        <img
                          alt="icon"
                          className="start_icon"
                          style={{ marginTop: '7px', width: '24px' }}
                          src={Warning}
                        />
                      ) : null
                    }
                    onChange={e => {
                      if (errors?.billing_zip_code)
                        setErrors(({ billing_zip_code, ...errors }) => ({ ...errors }));
                      setZipCode(e.target.value);
                      setIsValidZipCode(validateZipCode(e.target.value));
                    }}
                  />
                  {isSubmit && zipCode.length < 1 ? <ErrorToolbar /> : ''}
                  {errors?.billing_zip_code?.message || (!isValidZipCode && zipCode !== '') ? (
                    <ErrorMsg
                      title={errors?.billing_zip_code?.message ?? 'Invalid zip code!'}
                      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="PersonalInfoForm__submit">
            <Text on="white" UNSAFE_className="required-text">
              <span style={{ color: '#BD0000' }}>*</span>required
            </Text>
            <Button
              type="submit"
              kind="primary"
              size="l"
              width="115px"
              fonts="16px"
              weight="bolder"
              disabled={
                !validForm ||
                !selectedMLSSystems.length ||
                selectedMLSSystems.some(({ mls_id }) => !mls_id.trim()) ||
                Object.keys(errors).length
              }
            >
              Confirm
            </Button>
          </div>
        </Form>
      </div>
    </FlexBox>
  );
};
