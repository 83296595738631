import React from 'react';

import classNames from 'classnames';

import { AudienceStatusEnum } from 'enums';
import { TAudience } from 'types';

import './AudienceStatusAccessor.scss';

interface AudienceStatusAccessorProps extends Pick<TAudience, 'status'> {}

const AudienceStatusAccessor = ({ status }: AudienceStatusAccessorProps) => {
  const statusMap = {
    [AudienceStatusEnum.PENDING]: { className: 'pending', label: 'Pending...' },
    [AudienceStatusEnum.ACTIVE]: { className: 'available', label: 'Available' },
    [AudienceStatusEnum.ERROR]: { className: 'error', label: 'Error' },
    [AudienceStatusEnum.IN_USE]: { className: 'in_use', label: 'In Use' },
  };

  const { className, label } = statusMap[status] || { className: '', label: status };
  return <span className={classNames('audience-status', className)}>{label}</span>;
};

export default AudienceStatusAccessor;
