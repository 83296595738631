import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import toast from 'react-hot-toast';

import { usePermissionContext } from 'contexts/PermissionContext';
import { Button, FlexBox, Table, Text } from '@eltoro-ui/components';
import { Loading } from 'Components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/pro-solid-svg-icons';

import { getAudiences } from 'Requests/Request_Methods/audienceMethods';

import type { TAudience, TResPagination } from 'types';
import { AudienceStatusEnum } from 'enums';
import type { OrderlineDetailsParams } from 'Pages/OrderlineDetails/index';
import AudienceStatusAccessor from 'Pages/SavedAudiences/AudienceStatusAccessor';

interface AudienceTableProps {
  selectedAudiences: TAudience[];
  alreadySelected: TAudience[];
  handleNewSelectedProspectCount: (newList: TAudience[]) => void;
  setSelectedAudiences: React.Dispatch<React.SetStateAction<TAudience[]>>;
}
const AudienceTable = ({
  selectedAudiences,
  alreadySelected,
  handleNewSelectedProspectCount,
  setSelectedAudiences,
}: AudienceTableProps) => {
  const { orderlineId } = useParams<OrderlineDetailsParams>();

  const [audiences, setAudiences] = useState<TAudience[]>([]);

  const { userIsActive } = usePermissionContext();
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<TResPagination>({ total_count: 0, total_pages: 0 });

  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);

  const _fetchData = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getAudiences(page, 7, null, null, null, orderlineId);
      if (res.data?.audiences?.length) {
        setAudiences(prev => [...prev, ...res.data?.audiences]);
        const { total_audience, total_pages } = res.data;
        setPagination({ total_count: total_audience, total_pages });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail);
      setFetchDataIsLoading(false);
    }
  };

  useEffect(() => {
    if (userIsActive) {
      _fetchData();
    }
  }, [page]);

  const onSelect = (audienceList: TAudience[]) => {
    setSelectedAudiences(prevSelected => {
      const missingAudiences = alreadySelected.filter(
        selected => !audiences.some(a => a.id === selected.id)
      );
      const updatedSelectedAudiences = [...audienceList, ...missingAudiences];
      handleNewSelectedProspectCount(updatedSelectedAudiences);
      return updatedSelectedAudiences;
    });
  };

  const isUsedAudienceSelected = (id: number) =>
    alreadySelected.some(audience => audience.id === id);

  const isTableRowDisable = (audience: TAudience) => {
    return (
      audience.status === AudienceStatusEnum.ERROR ||
      audience.status === AudienceStatusEnum.PENDING ||
      (audience.status === AudienceStatusEnum.IN_USE && !isUsedAudienceSelected(audience.id))
    );
  };
  return (
    <div className="SavedAudiences scrolable-table">
      <Text on="white" UNSAFE_style={{ display: 'block', padding: '16px' }}>
        {pagination.total_count} saved audience{pagination.total_count > 1 ? 's' : ''}
      </Text>
      {!!audiences.length && (
        <>
          <Table<TAudience>
            rows={audiences}
            rowKey="id"
            rowDisabled={audience => isTableRowDisable(audience)}
            tableRowClass="CampaignSetup__tableRow"
            checkBorder="1px solid black"
            checkBoxActive="CampaignSetup__tableCheckbox"
            selectedRows={selectedAudiences}
            tableHeaderNoCheckbox
            fixedCellSize
            onSelect={onSelect}
            columns={[
              {
                path: 'name',
                label: 'Name',
                RowCell: audience => (
                  <Text on="white" size="m" tag="div" UNSAFE_className="table-audience-name">
                    {audience.name ?? '--'}
                  </Text>
                ),
              },
              {
                path: 'status',
                label: 'Status',
                RowCell: audience => <AudienceStatusAccessor status={audience.status} /> || '-',
              },
              {
                path: 'prospects_counts',
                label: 'Prospects',
                RowCell: audience => (
                  <Text on="white" size="l" weight="bold" UNSAFE_className="prospects_count">
                    <FontAwesomeIcon
                      icon={faWalking}
                      color={isTableRowDisable(audience) ? '#D1D1D1' : 'var(--color-secondary-300)'}
                    />{' '}
                    {audience.prospects_counts || '-'}
                  </Text>
                ),
              },
              {
                path: 'source',
                label: 'Source',
                RowCell: audience => (
                  <Text on="white" size="m" tag="div" UNSAFE_className="table-audience-name">
                    {audience.source ?? '--'}
                  </Text>
                ),
              },
            ]}
          />
          {pagination.total_pages > page && !fetchDataIsLoading && (
            <FlexBox justifyContent="center" UNSAFE_style={{ padding: '12px' }}>
              <Button kind="link" onClick={() => setPage(prevState => prevState + 1)}>
                Load more audiences
              </Button>
            </FlexBox>
          )}
        </>
      )}
      {fetchDataIsLoading && !audiences.length && <Loading />}
      {fetchDataIsLoading && !!audiences.length && <Loading style={{ minHeight: '0px' }} />}
    </div>
  );
};

export default AudienceTable;
