import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PageNav, PageRoutes } from '@eltoro-ui/components';
import { Loading, Layout } from 'Components';
import { ResetPassword } from 'Pages/ResetPassword';
import {
  TabWrapper,
  BillingSection,
  SubscriptionSection,
  PersonalInfoSection,
  Transaction,
  Feedback,
} from 'Pages/MyProfile/components';
import NotificationSetting from 'Pages/MyProfile/components/NotificationSetting/NotificationSetting';
// import CancelSubscription from 'Pages/MyProfile/components/CancelSubscription';
import TermsPolicies from 'Pages/MyProfile/components/TermsPolicies';
import Integrations from 'Pages/MyProfile/components/Integrations';

import type { TRootState } from 'types';

import './MyProfilePage.scss';
import DeactivateAccount from './components/DeactivateAccount';
import DeactivateInfo from './components/DeactivateAccount/components/DeactivateInfo';

export const MyProfilePage: FC = () => {
  const [profilePages, setprofilePages] = useState<any>([]);
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  useEffect(() => {
    setprofilePages([
      {
        path: '/profile',
        title: 'General Info',
        exactPath: true,
        component: () => (
          <TabWrapper>
            {!userData ? <Loading /> : <PersonalInfoSection user={userData} />}
          </TabWrapper>
        ),
      },
      {
        path: '/profile/billing',
        title: 'Billing Info',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <SubscriptionSection />
            <Transaction />
            <BillingSection />
            {/* <CancelSubscription user={userData} /> */}
          </TabWrapper>
        ),
      },

      {
        path: '/profile/notifications',
        title: 'Feedback',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <Feedback />
          </TabWrapper>
        ),
      },
      {
        path: '/profile/notification-settings',
        title: 'Notification Settings',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <NotificationSetting />
          </TabWrapper>
        ),
      },
      {
        path: '/profile/terms-policies',
        title: 'Terms and Policies',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <TermsPolicies />
          </TabWrapper>
        ),
      },
      {
        path: '/profile/integrations',
        title: 'Integrations',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <Integrations />
          </TabWrapper>
        ),
      },
    ]);
  }, [userData]);

  const profilePageRoutes = useMemo(() => {
    return [
      ...profilePages,
      {
        path: '/profile/password-reset',
        title: 'Reset Password',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <ResetPassword />
          </TabWrapper>
        ),
      },
    ];
  }, [profilePages]);

  return (
    <Layout>
      <div className="MyProfilePage">
        <DeactivateInfo />
        <BrowserRouter>
          <nav className="MyProfilePage__nav">
            <h1 className="MyProfilePage__header">Account Settings</h1>
            <PageNav pages={profilePages} userData={userData} />
          </nav>

          <main className="MyProfilePage__main">
            <PageRoutes pages={profilePageRoutes} />
            <DeactivateAccount user={userData} />
          </main>
        </BrowserRouter>
      </div>
    </Layout>
  );
};
