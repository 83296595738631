import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';

import type { Column, Row } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/pro-solid-svg-icons';

import { Button } from '@eltoro-ui/components';
import { EmptyStatus, PageHeader, ReactTable, ReactTableSearchBar } from 'Components';
import { Tags } from 'Pages/SavedAudiences/components';
import AudienceStatusAccessor from 'Pages/SavedAudiences/AudienceStatusAccessor';

import { deleteAudience, getAudiences } from 'Requests/Request_Methods/audienceMethods';
import { usePermissionContext } from 'contexts/PermissionContext';

import type { TAudience, TResPagination } from 'types';
import { AudienceStatusEnum } from 'enums';

import './SavedAudiences.scss';

export const SavedAudiences = () => {
  const { userIsDeactivatedWithActiveOrderlines } = usePermissionContext();
  const [audiences, setAudiences] = useState<TAudience[]>([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const { userIsActive } = usePermissionContext();
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<TResPagination>({ total_count: 0, total_pages: 0 });
  const [searchValue, setSearchValue] = useState('');
  // ========== New Table Utilities
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };
  const onDelete = async (_items: Array<Row<TAudience>>) => {
    setDeleteIsLoading(true);
    const ids = _items.map(item => item.original.id);
    try {
      const { data } = await deleteAudience({ ids });
      if (data?.msg) toast.success(data.msg);
      setDeleteModalIsOpen(false);
      setDeleteIsLoading(false);

      if (page === 1) _fetchData();
      else await setPage(1);
    } catch (err: any) {
      toast.error(err.detail);
      setDeleteIsLoading(false);
    }
  };

  const _fetchData = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getAudiences(page, 50, searchValue, null);
      if (res.data?.audiences) {
        setAudiences(res.data?.audiences);
        const { total_audience, total_pages } = res.data;
        setPagination({ total_count: total_audience, total_pages });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail);
      setFetchDataIsLoading(false);
    }
  };

  useEffect(() => {
    if (userIsActive) {
      _fetchData();
    }
  }, [page, searchValue]);

  const columns: Column<TAudience>[] = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Audience Name',
          accessor: 'name',
        },
        {
          Header: 'Prospects',
          accessor: 'prospects_counts',
          Cell: ({ row: { original: audience } }) => (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faWalking}
                color={
                  audience.status !== AudienceStatusEnum.ACTIVE
                    ? '#B0B0B0'
                    : 'var(--color-secondary-300)'
                }
                style={{ marginRight: '5px' }}
              />
              {audience.prospects_counts || '-'}
            </span>
          ),
        },
        {
          Header: 'Status',
          accessor: audience => <AudienceStatusAccessor status={audience.status} /> || '-',
        },
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Segment',
          accessor: 'segment',
          Cell: ({ row: { original: audience } }) => <span>{audience.segment || '-'}</span>,
        },
        {
          Header: 'Date Uploaded',
          accessor: audience => moment(`${audience.created_at}Z`).format('MM/DD/YYYY hh:mm A'),
        },
        {
          Header: 'Tags',
          accessor: audience =>
            audience.tags?.length ? (
              <span className="SavedAudiences__tagsContainer">
                <Tags data={audience.tags} />
              </span>
            ) : (
              '-'
            ),
          // @ts-ignore
          disableSortBy: true,
        },
      ],
    },
  ];

  return (
    <div className="SavedAudiences">
      <PageHeader
        UNSAFE_CLASSNAME="SavedAudiences__PageHeader"
        LeftContent_ClassName="MyListings__header"
        subTitle={
          <span style={{ marginLeft: '5px' }} className="MyListings__totalCounts">
            You have <b>{pagination.total_count} Saved Audience</b>
          </span>
        }
        actions={[
          <ReactTableSearchBar
            key="search-audiences"
            searchValue={searchValue}
            handleSearchValue={handleSearchValue}
            placeholder="Search by audience name"
          />,
          <Button
            key="new-campaign"
            kind="primary"
            to="?action=create-campaign"
            replace
            weight="bolder"
            size="l"
            disabled={userIsDeactivatedWithActiveOrderlines}
          >
            New Campaign
          </Button>,
        ]}
      />
      <ReactTable<TAudience>
        title="audience"
        deleteModalNote="The audiences attached to campaign(s) will not be deleted"
        deleteModalHeader="Are you sure you want to delete the selected audience(s)?"
        loading={fetchDataIsLoading}
        emptyText={
          <EmptyStatus
            heading={searchValue && !audiences.length ? 'No results found' : undefined}
            subHeading={
              searchValue && !audiences.length
                ? 'You might consider trying different keywords, double-checking for any spelling errors, or adjusting your filters.'
                : 'You can try creating a new campaign to get a better audience.'
            }
          />
        }
        data={audiences}
        onDelete={onDelete}
        rowDisabled={audience => audience.status !== AudienceStatusEnum.ACTIVE}
        deleteModal={deleteModalIsOpen}
        setDeleteModal={setDeleteModalIsOpen}
        deleteIsLoading={deleteIsLoading}
        columns={columns}
        currentPage={page}
        setPage={setPage}
        pageCount={pagination.total_pages}
        totalCount={pagination.total_count}
      />
    </div>
  );
};
